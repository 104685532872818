












import { Component, Vue } from 'vue-property-decorator'

import Headline from '@/modules/shared/components/Headline.vue'
import LoginForm from '../components/LoginForm.vue'
import RecoverPassword from '../components/RecoverPassword.vue'

@Component({
  components: {
    Headline,
    LoginForm,
    RecoverPassword,
  },
})
export default class Login extends Vue {}
