














import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import PromptDialog from '@/modules/shared/components/PromptDialog.vue'

const authModule = namespace('auth')

@Component({
  components: {
    PromptDialog,
  },
})
export default class RecoverPassword extends Vue {
  @authModule.Action
  private readonly recoverPassword!: (username: string) => Promise<void>

  private promptDialog = false

  private sendRecoverPassword(username: string) {
    this.promptDialog = false
    this.recoverPassword(username)
  }
}
