











































import { Component, Mixins, Prop } from 'vue-property-decorator'

import ValidatorMixin from '@/modules/shared/mixins/ValidatorMixin'

@Component
export default class PromptDialog extends Mixins(ValidatorMixin) {
  @Prop()
  private readonly value!: unknown

  @Prop({ required: true })
  private readonly message!: string

  private inputValue = ''

  private confirm() {
    if (!this.validate()) return

    this.$emit('confirm', this.inputValue)
    this.reset()
  }

  private cancel(value: unknown) {
    this.reset()
    this.$emit('input', value)
  }
}
