








































import { Component, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import BasicCredentials from '@/modules/shared/entities/BasicCredentials'

import PrimaryButton from '@/modules/shared/components/PrimaryButton.vue'
import ValidatorMixin from '@/modules/shared/mixins/ValidatorMixin'

const authModule = namespace('auth')

@Component({
  components: {
    PrimaryButton,
  },
})
export default class LoginForm extends Mixins(ValidatorMixin) {
  @authModule.Action
  private readonly login!: (credentials: BasicCredentials) => Promise<void>

  private form = new BasicCredentials()

  async submit(): Promise<void> {
    const { redirect = '/admin' } = this.$route.query

    if (!this.validate()) return

    try {
      await this.login(this.form)

      this.$router.replace({
        path: redirect as string,
      })
    } catch (err) {
      // TODO: handle this error
    }
  }
}
